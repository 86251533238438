import request from '@/utils/request'

// 查询标签配置列表
export function listLabel(query) {
  return request({
    url: '/config/label/list',
    method: 'get',
    params: query
  })
}

export function totalListLabel(query) {
  return request({
    url: '/config/label/loadLabelOptions',
    method: 'get',
    params: query
  })
}

// 查询标签配置详细
export function getLabel(id) {
  return request({
    url: '/config/label/' + id,
    method: 'get'
  })
}

// 新增标签配置
export function addLabel(data) {
  return request({
    url: '/config/label',
    method: 'post',
    data: data
  })
}

// 修改标签配置
export function updateLabel(data) {
  return request({
    url: '/config/label',
    method: 'put',
    data: data
  })
}

// 删除标签配置
export function delLabel(id) {
  return request({
    url: '/config/label/' + id,
    method: 'delete'
  })
}
